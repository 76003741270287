window.addEventListener('DOMContentLoaded', () => {
    const phoneMenu = document.querySelector('.phone-menu');
    const phoneMenuItems = phoneMenu?.querySelectorAll('.menu-item');
    const trigger = document.querySelector('.phone-menu-trigger');

    trigger.addEventListener('click', () => {
        phoneMenu.classList.toggle('is-active');
    });

    phoneMenuItems.forEach(item => {
        item.addEventListener('click', () => {
            phoneMenu.classList.remove('is-active');
        });
    });
})